import {
	Component,
	computed,
	ElementRef,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from "@angular/core"
import { Test } from "@app/definitions/types"
import { Modalable } from "@app/Modalable"

@Component({
	selector: "app-test-manage",
	templateUrl: "./test-manage.component.html",
	styleUrl: "./test-manage.component.css",
})
export class TestManageComponent extends Modalable {
	modalTitleLang: string = "tests_manage"
	modalWidth: string = "modal-1000"
	newTest: Test = new Test()
	isFormSubmittable: boolean = false
	tests: Test[] = []
	topTests: Test[] = []
	topTestsForSelect: Test[] = []
	validationRules: any = {
		//default - is new contact
		name: { not_empty: true, unique: true },
	}
	@ViewChildren("firstField") firstField: QueryList<any>
	isNewSaving: boolean = false
	allFields: string[] = [
		"name",
		"patient_sign_required",
		"user_sign_required",
		"is_locked",
		"calculated",
		"active",
		"flip",
		"questions",
		"action",
	]
	upperMobileFields: string[] = [
		"name",
		"patient_sign_required",
		"user_sign_required",
	]
	active: string = "yes"

	upperFields: string[] = []
	upperFieldsTh: string[] = []
	mobileFields: string[] = []

	async ngOnInit() {
		this.upperFields = this.eventsService.isMobile
			? this.upperMobileFields
			: this.allFields
		this.upperFieldsTh = this.eventsService.isMobile
			? ["", ...this.upperMobileFields]
			: this.allFields
		this.mobileFields = this.allFields.filter(
			(fieldName) => !this.upperMobileFields.includes(fieldName)
		)

		const res: any = await this.store.get_gen_items("tests")
		this.tests = res?.tests.filter((it) => it.public === "no")
		this.tests.forEach((test) => (test.errors = {}))
		this.updateTests()
		this.firstFieldFocusTO()
	}

	async firstFieldFocusTO() {
		await this.store.timeout(100)
		this.focusfirstField()
	}

	focusfirstField() {
		if (!this.firstField?.length) {
			return
		}
		let element = this.firstField.first
		if (element) {
			element.focus()
		}
	}

	updateTests() {
		this.topTests = this.tests.filter((row) => !row.test_id)
		this.topTests.forEach((topTest) => {
			topTest.tests = this.tests.filter((row) => row.test_id == topTest.id)
			topTest.tests.sort((a: Test, b: Test) => a.order_num - b.order_num)
			topTest.tests.forEach((test) => {
				test.is_highest_order = false
				test.is_lowest_order = false
			})
			if (topTest.tests?.length) {
				topTest.tests[0].is_lowest_order = true
				topTest.tests[topTest.tests.length - 1].is_highest_order = true
			}
		})
		this.topTestsForSelect = this.topTests.filter((test) => {
			if (test?.is_documented || test.is_locked == "yes") {
				return false
			}
			if (
				this.store
					.getSiteDataTable("testquestions")
					.find((tq) => tq.test_id == test.id)
			) {
				return false
			}

			return true
		})
	}

	async moveOrderNum(subTest: Test, toLowerOrderNum: boolean) {
		const test = this.tests.find((it) => it.id == subTest.test_id)
		const subTestToFlip = this.store.findClosestOrderNum(
			test.tests,
			subTest.order_num,
			toLowerOrderNum
		)
		if (subTestToFlip) {
			const flipOrderNum = subTestToFlip.order_num
			subTest.isFlipOrderSending = true
			const res = await this.apiService.post("flip_order_num", {
				id1: subTest.id,
				id2: subTestToFlip.id,
				tableName: "tests",
			})
			subTest.isFlipOrderSending = false
			if (res.success) {
				this.modalService.openToast(this.lang.getVal("update_successfuly"))
				subTestToFlip.order_num = subTest.order_num
				subTest.order_num = flipOrderNum
				this.store.updGenItemRowField(
					"tests",
					subTest.id,
					"order_num",
					subTest.order_num
				)
				this.store.updGenItemRowField(
					"tests",
					subTestToFlip.id,
					"order_num",
					subTestToFlip.order_num
				)
				this.updateTests()
			} else {
				this.modalService.openToast(this.lang.getVal("save_failed"))
			}
		}
	}

	validateField(row: Test, fieldName: string) {
		row.errors = {}
		const isEditSubmittable = this.validator.validateEntireForm(
			row,
			row.errors,
			this.validationRules,
			this.tests
		)
		if (isEditSubmittable) {
			this.editFieldTest(row, fieldName)
		}
	}

	validateEntireForm() {
		//validate all fields and detrmine if can be submitted (or has errors)
		this.newTest.errors = {}
		this.isFormSubmittable = this.validator.validateEntireForm(
			this.newTest,
			this.newTest.errors,
			this.validationRules,
			this.tests
		)
	}

	async saveNewTest() {
		if (!this.isFormSubmittable) {
			return
		}
		const sendObj = { ...this.newTest }
		delete sendObj.errors
		this.isNewSaving = true
		const res: any = await this.apiService.save_gen_item("tests", sendObj)
		this.isNewSaving = false
		if (res?.id) {
			this.modalService.openToast(this.lang.getVal("saved_successfully"))
			res.errors = {}
			this.store.addGenItemRow("tests", res)
			this.tests = [...this.tests, res]
			this.newTest = new Test()
			this.isFormSubmittable = false
			this.updateTests()
			// this.firstFieldFocusTO();
			this.goToTestUpdate(res)
		} else {
			this.modalService.openToast(this.lang.getVal("save_failed"))
		}
	}

	async editFieldTest(test: Test, fieldName: string) {
		const res: any = await this.apiService.save_gen_field(
			"tests",
			test.id,
			fieldName,
			test[fieldName]
		)
		if (res?.success) {
			this.store.updGenItemRowField(
				"tests",
				test.id,
				fieldName,
				test[fieldName]
			)
			this.modalService.openToast(this.lang.getVal("update_successfuly"))
		} else {
			this.modalService.openToast(this.lang.getVal("save_failed"))
		}
	}
	async deleteTest(test: Test) {
		const confirmDelete = await this.modalService.openMulti("confirm", {
			objectName: test.name,
		})
		if (!confirmDelete) {
			return
		} //if confirmation failed - return

		test.isDeleting = true
		const res: any = await this.apiService.delete_gen_item("tests", test.id)
		test.isDeleting = false
		if (res.success) {
			this.store.deleteTest(test.id)

			this.tests = this.tests.filter((it) => it.id != test.id)
			this.updateTests()
			this.modalService.openToast(this.lang.getVal("delete_successful"))
		} else {
			this.modalService.openToast(this.lang.getVal("delete_failed"))
		}
	}
	async openQuestions(test: Test, is_documented: boolean = false) {
		await this.modalService.openMulti("testquestions-manage", {
			test_id: test.id,
			is_documented,
			is_locked: test.is_locked,
		})
		this.updateTests()
	}

	openTestAnswers(test: Test) {
		this.modalService.openMulti("testanswers-fill", {
			test_id: test.id,
		})
	}

	async duplicateTest(row: any) {
		row.spinDup = true
		const res = await this.apiService.post("duplicate_test", {
			test_id: row.id,
		})
		row.spinDup = false
		if (res?.tests) {
			res.tests.forEach((test) => {
				this.store.addGenItemRow("tests", test)
			})
		}
		if (res?.testquestions) {
			res.testquestions.forEach((testquestion) => {
				this.store.addGenItemRow("testquestions", testquestion)
			})
		}
		this.ngOnInit()
	}

	async goToTestUpdate(test: any) {
		await this.modalService.openMulti("update-test", { test })
		this.ngOnInit()
	}

	yesNoClick(curRow: any, fieldName: any, isNewRow: boolean) {
		curRow[fieldName] = curRow[fieldName] == "yes" ? "no" : "yes"
		if (isNewRow) {
			this.validateEntireForm()
		} else {
			this.editFieldTest(curRow, fieldName)
		}
	}
}
