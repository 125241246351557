import { DateFormatPipe } from "./utils/date-format.pipe"
import { FinNumberPipe } from "./utils/finNumber.pipe"
import { endDateNullPipe } from "./utils/endDateNull.pipe"
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http"
import { ErrorHandler, NgModule, isDevMode } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { BrowserModule } from "@angular/platform-browser"
import { FullCalendarModule } from "@fullcalendar/angular" // the main connector. must go first

import { NgxChartsModule } from "@swimlane/ngx-charts"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"

import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { CalendarComponent } from "./calendar/calendar.component"
import { CcParamsAccountantComponent } from "./cc-params-accountant/cc-params-accountant.component"
import { CcParamsHashavComponent } from "./cc-params-hashav/cc-params-hashav.component"
import { CcParamsJournalComponent } from "./cc-params-journal/cc-params-journal.component"
import { CcParamsAutomationComponent } from "./cc-params-automation/cc-params-automation.component"
import { CcParamsTaxesComponent } from "./cc-params-taxes/cc-params-taxes.component"
import { ConfigCliniqComponent } from "./config-cliniq/config-cliniq.component"
import { ContactComponent } from "./contact.component"
import { GenAutocompleteComponent } from "./gen-inputs/gen-autocomplete/gen-autocomplete.component"
import { GenButtonGroupComponent } from "./gen-inputs/gen-button-group/gen-button-group.component"
import { GenFileInputComponent } from "./gen-inputs/gen-file-input/gen-file-input.component"
import { GenInputComponent } from "./gen-inputs/gen-input/gen-input.component"
import { GenSelectComponent } from "./gen-inputs/gen-select/gen-select.component"
import { GenTablePaginationComponent } from "./gen-table-pagination/gen-table-pagination.component"
import { GenTableComponent } from "./gen-table/gen-table.component"
import { GenTable2Component } from "./gen-table2/gen-table2.component"
import { FctryComponent } from "./fctry/fctry.component"

import { HomeMeetingComponent } from "./home-meeting/home-meeting.component"
import {
	HomeComponent,
	HomeAdminComponent,
	HomeSuperAdminComponent,
} from "./home/home.component"
import { LoginComponent } from "./login/login.component"
import { MenuComponent } from "./menu/menu.component"
import { AddDocumentComponent } from "./add-document/add-document.component"
import { AddInvoiceComponent } from "./add-invoice/add-invoice.component"
import { AddMeetingComponent } from "./add-meeting/add-meeting.component"
import { AddPrescriptionComponent } from "./add-prescription/add-prescription.component"
import { AutotextMenuComponent } from "./autotext-menu/autotext-menu.component"
import { ChangePasswordComponent } from "./change-password/change-password.component"
import { ConfirmComponent } from "./confirm/confirm.component"
import { DataExportComponent } from "./data-export/data-export.component"
import { ErrorComponent } from "./error/error.component"
import { PromptInputFieldComponent } from "./prompt-input-field/prompt-input-field.component"
import { GenTableAddItemComponent } from "./gen-table-add-item/gen-table-add-item.component"
import { GenTableDisplayComponent } from "./gen-table-display/gen-table-display.component"
import { GroupAttendsComponent } from "./group-attends/group-attends.component"
import { GroupAttendsRowComponent } from "./group-attends-row/group-attends-row.component"
import { ImportPatientsComponent } from "./imports/import-common/import-common.component"
import { OtpComponent } from "./otp/otp.component"
import { PreviewFinresourceComponent } from "./preview-finresource/preview-finresource.component"
import { ReportsComponent } from "./reports/reports.component"
import { StatusComponent } from "./status/status.component"
import { TaxesReportComponent } from "./taxes-report/taxes-report.component"
import { UpdateGroupComponent } from "./update-group/update-group.component"
import { UploadDocumentComponent } from "./upload-document/upload-document.component"
import { PatientComponent } from "./patient/patient.component"
import { PaymentFormComponent } from "./payment-form/payment-form.component"
import { PayorComponent } from "./payor.component"
import { ErrorService } from "./services/error-service.service"
import { RegInterceptor } from "./services/reg.interceptor"
import { UpdateContactComponent } from "./update-contact/update-contact.component"
import { UpdatePatientComponent } from "./update-patient/update-patient.component"
import { UserComponent } from "./user/user.component"
import { DatePickerComponent } from "./utils/date-picker/date-picker.component"
import { ModalDragDirective } from "./utils/modal-drag.directive"
import { SafeHtmlPipe } from "./utils/safe-html.pipe"
import { TimePickerComponent } from "./utils/time-picker/time-picker.component"
import { ToastComponent } from "./utils/toast.component"
import { WysiwygComponent } from "./utils/wysiwyg/wysiwyg.component"
import { FooterComponent } from "./footer/footer.component"
import { UpdatePayorComponent } from "./update-payor/update-payor.component"
import { PlaygroundComponent } from "./playground/playground.component"
import { BannerComponent } from "./utils/banner/banner.component"
import { ProfitlossComponent } from "./profitloss/profitloss.component"
import { SendUsersEmailComponent } from "./send-users-email/send-users-email.component"
import { ImageGalleryComponent } from "./image-gallery/image-gallery.component"
import { TermsComponent } from "./terms/terms.component"
import { PaymentFinishComponent } from "./payment-finish/payment-finish.component"
import { TimeFormatPipe } from "./utils/time-format.pipe"
import { ChangeLimitDateComponent } from "./change-limit-date/change-limit-date.component"
import { CcParamsHolidayComponent } from "./cc-params-holiday/cc-params-holiday.component"
import { FinresourceTranslationComponent } from "./finresource-translation/finresource-translation.component"
import { PrintEmailPromptComponent } from "./print-email-prompt/print-email-prompt.component"
import { LangValPipe } from "./utils/lang-val.pipe"
import { ConfigValPipe } from "./utils/config-val"
import { MixedListComponent } from "./mixed-list/mixed-list.component"
import {
	SortThComponent,
	YesNo,
	Icon,
	Spin,
	SpinOrContent,
	ToastDownloadComponent,
	TestDisplayComponent,
	TableWrapComponent,
	DivWrapComponent,
	SpinnerComponent,
	LangAttrDirective,
	CancelBtnComponent,
	TabClickDirective,
	ContactwayAddComponent,
	BtnExtComponent,
	PreIconComponent,
	PostIconComponent,
	PatientModalComponent,
} from "./utils/utils.component"
import { MobileTabsComponent } from "./utils/mobile-tabs/mobile-tabs.component"
import { TimeAllocationComponent } from "./time-allocation/time-allocation.component"
import { TimeAllocationFormComponent } from "./time-allocation-form/time-allocation-form.component"
import { BkmvdataReportComponent } from "./bkmvdata-report/bkmvdata-report.component"
import { NewUserComponent } from "./new-user/new-user.component"
import { AddTaskComponent } from "./add-task/add-task.component"
import { FinresourceCreditComponent } from "./finresource-credit/finresource-credit.component"
import { AutocompleteWithTagsComponent } from "./gen-inputs/autocomplete-with-tags/autocomplete-with-tags.component"
import { FollowupsUpdateListComponent } from "./followups-update-list/followups-update-list.component"
import { AutocompleteMultiComponent } from "./gen-inputs/autocomplete-multi/autocomplete-multi.component"
import { CcParamsSubUserComponent } from "./cc-params-sub-user/cc-params-sub-user.component"
import { ImportTagsComponent } from "./imports/import-common/import-common.component"
import { ImportFollowupsComponent } from "./imports/import-followups.component"
import { SignDocumentsComponent } from "./sign-documents/sign-documents.component"
import { TermsSmsComponent } from "./terms-sms/terms-sms.component"
import { SubUserPaymentsComponent } from "./sub-user-payments/sub-user-payments.component"
import { UpdateFieldComponent } from "./update-field/update-field.component"

import { GraphsDataComponent } from "./graphs-data/graphs-data.component"
import { CcParamsZonesComponent } from "./cc-params-zones/cc-params-zones.component"
import { CcParamsPublicTestsComponent } from "./cc-params-public-tests/cc-params-public-tests.component"
import { TaskComponent } from "./task/task.component"
import { FaqListComponent } from "./faq-list/faq-list.component"
import { PatientFieldTblComponent } from "./patient-field-tbl/patient-field-tbl.component"
import { InsertPatientFormComponent } from "./insert-patient-form/insert-patient-form.component"
import { TaExpandsComponent } from "./utils/ta-expands/ta-expands.component"
import { ActivityComponent } from "./activity/activity.component"
import { ActivityLogTableComponent } from "./activity-log-table/activity-log-table.component"
import { SubUserPatientsComponent } from "./sub-user-patients/sub-user-patients.component"
import { FollowupsForSupervisorListComponent } from "./followups-for-supervisor-list/followups-for-supervisor-list.component"
import { TestManageComponent } from "./test-manage/test-manage.component"
import { TestquestionsManageComponent } from "./testquestions-manage/testquestions-manage.component"
import {
	TestscalesComponent,
	TestscaleComponent,
	TestscaleQuestionsChecksComponent,
	TestscaleTblFieldComponent,
} from "./testscales/testscales.component"
import { TestGraphComponent } from "./test-graph/test-graph.component"
import { TitleLangDirective } from "./utils/title-lang.directive"
import { Google2faQrCodeComponent } from "./google2fa-qr-code/google2fa-qr-code.component"
import { ClientPaymentFormComponent } from "./client-payment-form/client-payment-form.component"
import { GuestPaymentFormComponent } from "./guest-payment-form/guest-payment-form.component"
import { CreateTakbullAccountComponent } from "./create-takbull-account/create-takbull-account.component"
import { CreateGrowAccountComponent } from "./create-grow-account/create-grow-account.component"
import { ItaGetAuthComponent } from "./ita-get-auth/ita-get-auth.component"
import { ItaRefreshTokenComponent } from "./ita-refresh-token/ita-refresh-token.component"
import { CopyLinkComponent } from "./copy-link/copy-link.component"
import { PrivacyComponent } from "./privacy/privacy.component"
import { PaymentClientFinishComponent } from "./payment-client-finish/payment-client-finish.component"
import { PaymentClientFinishGrowComponent } from "./payment-client-finish-grow/payment-client-finish-grow.component"
import { PaymentClientFailGrowComponent } from "./payment-client-fail-grow/payment-client-fail-grow.component"
import { MenuContactwaySearchComponent } from "./menu-contactway-search/menu-contactway-search.component"
import { GuestAddMeetingFormComponent } from "./guest-add-meeting-form/guest-add-meeting-form.component"
import { GuestSchedComponent } from "./guest-sched/guest-sched.component"
import { GuestCalendar2Component } from "./guest-calendar2/guest-calendar2.component"
import { CanvasWriteComponent } from "./canvas-write/canvas-write.component"
import { PatientUnifyComponent } from "./patient-unify/patient-unify.component"
import { UpdateTestComponent } from "./update-test/update-test.component"
import { InsertAutotextComponent } from "./insert-autotext/insert-autotext.component"
import { UserdetailLinesManageComponent } from "./userdetail-lines-manage/userdetail-lines-manage.component"
import { CcParamsUserDetailsWebsiteComponent } from "./cc-params-user-details-website/cc-params-user-details-website.component"
import { GuestUserDetailsComponent } from "./guest-user-details/guest-user-details.component"
import { GuestHoursSchedComponent } from "./guest-hours-sched/guest-hours-sched.component"
import { ProductPersonsComponent } from "./product-persons/product-persons.component"
import { InvitePartnerComponent } from "./invite-partner/invite-partner.component"
import { MrMotionsComponent } from "./mr-motions/mr-motions.component"
import { ChoosePartnerComponent } from "./choose-partner/choose-partner.component"
import { CcParamsSwitchComponent } from "./cc-params-switch/cc-params-switch.component"
import { MeetingRequestsComponent } from "./meeting-requests/meeting-requests.component"
import { MeetingTimesComponent } from "./meeting-times/meeting-times.component"
import { CcSectionsComponent } from "./cc-sections/cc-sections.component"
import { PatientTestFillComponent } from "./patient-test-fill/patient-test-fill.component"
import { PatientDetailsFillComponent } from "./patient-details-fill/patient-details-fill.component"
import { TestanswersFillComponent } from "./testanswers-fill/testanswers-fill.component"
import { MultiModalsComponent } from "./modals/multi-modals.component"
import { CcParamsUserDetailsComponent } from "./cc-params-user-details/cc-params-user-details.component"
import { AcCommonComponent } from "./gen-inputs/ac-common/ac-common.component"
import { PatientFusesComponent } from "./patient-fuses/patient-fuses.component"
import { ImportCommonComponent } from "./imports/import-common/import-common.component"
import {
	PersonCommonComponent,
	PersonSideItemComponent,
} from "./person-common/person-common.component"
import { CcParamsSecurityComponent } from "./cc-params-security/cc-params-security.component"
import { CcParamsSelfCoordinationComponent } from "./cc-params-self-coordination/cc-params-self-coordination.component"
import { OpenInstructionsComponent } from "./open-instructions/open-instructions.component"
import { OpenSpecialNotificationComponent } from "./open-special-notification/open-special-notification.component"
import { GenModalComponent } from "./modals/gen-modal/gen-modal.component"
import { InArrayPipe } from "./utils/in-array.pipe"
import { TestsMenuComponent } from "./tests-menu/tests-menu.component"
import { TypesCreateComponent } from "./types-create/types-create.component"
import { AddMeetingRequestComponent } from "./add-meeting-request/add-meeting-request.component"
import {
	CcCheckboxesComponent,
	CcInsertPatientTemplate,
	CcReports,
} from "./cc-checkboxes/cc-checkboxes.component"
import { PersonUpdateCommons } from "./person-update-commons/person-update-commons.component"
import { ChooseMirrorUserComponent } from "./choose-mirror-user/choose-mirror-user.component"
import { WalkthroughComponent } from "./walkthrough/walkthrough.component"
import {
	PatientDetailsFieldsManageComponent,
	PatientDetailsFieldsRowComponent,
} from "./patient-details-fields-manage/patient-details-fields-manage.component"

@NgModule({
	declarations: [
		AppComponent,
		ConfigCliniqComponent,
		GenInputComponent,
		GenSelectComponent,
		GenButtonGroupComponent,
		LoginComponent,
		// ExpensetypesTableComponent,
		GenAutocompleteComponent,
		ConfirmComponent,
		MenuComponent,
		// MeetingtypesTableComponent,
		CalendarComponent,
		// FollowuptypesTableComponent,
		// ContacttypesTableComponent,
		GenTableComponent,
		GenTable2Component,
		FctryComponent,
		ToastComponent,
		// AnnouncesTableComponent,
		// ContactsTableComponent,
		// ContactwaysTableComponent,
		HomeComponent,
		// CreditcardsTableComponent,
		// CreditcompsTableComponent,
		// CredittypesTableComponent,
		// DiagpsTableComponent,
		// MedicsTableComponent,
		// LabtestsTableComponent,
		// NondrugsTableComponent,
		GenTablePaginationComponent,
		HomeMeetingComponent,
		// PatientsTableComponent,
		// DiagppatientsTableComponent,
		// MedicpatientsTableComponent,
		// LabtestpatientsTableComponent,
		// NondrugpatientsTableComponent,
		// FollowupsTableComponent,
		// FollowupseriesTableComponent,
		// BanksTableComponent,
		// BranchesTableComponent,
		// BankaccountsTableComponent,
		// DocumentsTableComponent,
		AddMeetingComponent,
		AddInvoiceComponent,

		ReportsComponent,
		CcParamsHashavComponent,
		CcParamsJournalComponent,
		CcParamsAutomationComponent,
		CcParamsTaxesComponent,
		CcParamsAccountantComponent,
		TaxesReportComponent,
		DatePickerComponent,
		TimePickerComponent,
		GenTableAddItemComponent,
		ModalDragDirective,
		TitleLangDirective,
		PromptInputFieldComponent,
		GenTableDisplayComponent,
		GenFileInputComponent,
		WysiwygComponent,
		PreviewFinresourceComponent,
		PatientComponent,
		UpdatePatientComponent,
		UpdateContactComponent,
		ContactComponent,
		PayorComponent,
		DataExportComponent,
		ImportPatientsComponent,
		UserComponent,
		StatusComponent,
		SafeHtmlPipe,
		DateFormatPipe,
		FinNumberPipe,
		endDateNullPipe,
		TimeFormatPipe,
		LangValPipe,
		ConfigValPipe,
		AutotextMenuComponent,
		TestsMenuComponent,
		OtpComponent,
		ChangePasswordComponent,
		ErrorComponent,
		AddPrescriptionComponent,
		AddDocumentComponent,
		UploadDocumentComponent,
		UpdateGroupComponent,
		GroupAttendsComponent,
		GroupAttendsRowComponent,
		PaymentFormComponent,
		FooterComponent,
		UpdatePayorComponent,
		PlaygroundComponent,
		BannerComponent,
		ProfitlossComponent,
		SendUsersEmailComponent,
		ImageGalleryComponent,
		TermsComponent,
		PrivacyComponent,
		PaymentFinishComponent,
		ChangeLimitDateComponent,
		CcParamsHolidayComponent,
		FinresourceTranslationComponent,
		PrintEmailPromptComponent,
		MixedListComponent,
		SortThComponent,
		YesNo,
		SpinOrContent,
		TimeAllocationComponent,
		TimeAllocationFormComponent,
		BkmvdataReportComponent,
		NewUserComponent,
		AddTaskComponent,
		FinresourceCreditComponent,
		AutocompleteWithTagsComponent,
		HomeAdminComponent,
		FollowupsUpdateListComponent,
		AutocompleteMultiComponent,
		CcParamsSubUserComponent,
		ImportTagsComponent,
		ImportFollowupsComponent,
		SignDocumentsComponent,
		TermsSmsComponent,
		SubUserPaymentsComponent,
		UpdateFieldComponent,
		GraphsDataComponent,
		CcParamsZonesComponent,
		CcParamsPublicTestsComponent,
		HomeSuperAdminComponent,
		TaskComponent,
		FaqListComponent,
		PatientFieldTblComponent,
		InsertPatientFormComponent,
		TaExpandsComponent,
		ActivityComponent,
		ActivityLogTableComponent,
		SubUserPatientsComponent,
		FollowupsForSupervisorListComponent,
		TestManageComponent,
		TestquestionsManageComponent,
		TestscalesComponent,
		TestscaleComponent,
		TestscaleQuestionsChecksComponent,
		TestscaleTblFieldComponent,
		TestGraphComponent,
		Google2faQrCodeComponent,
		ClientPaymentFormComponent,
		GuestPaymentFormComponent,
		CreateTakbullAccountComponent,
		CreateGrowAccountComponent,
		ItaGetAuthComponent,
		ItaRefreshTokenComponent,
		CopyLinkComponent,
		PrivacyComponent,
		PaymentClientFinishComponent,
		PaymentClientFinishGrowComponent,
		PaymentClientFailGrowComponent,
		MenuContactwaySearchComponent,
		GuestAddMeetingFormComponent,
		GuestSchedComponent,
		GuestCalendar2Component,
		CanvasWriteComponent,
		PatientUnifyComponent,
		UpdateTestComponent,
		InsertAutotextComponent,
		UserdetailLinesManageComponent,
		CcParamsUserDetailsWebsiteComponent,
		GuestUserDetailsComponent,
		GuestHoursSchedComponent,
		ProductPersonsComponent,
		InvitePartnerComponent,
		MrMotionsComponent,
		ChoosePartnerComponent,
		CcParamsSwitchComponent,
		MeetingRequestsComponent,
		MeetingTimesComponent,
		CcSectionsComponent,
		PatientTestFillComponent,
		PatientDetailsFillComponent,
		TestanswersFillComponent,
		MultiModalsComponent,
		Icon,
		Spin,
		CcParamsUserDetailsComponent,
		ToastDownloadComponent,
		TestDisplayComponent,
		MobileTabsComponent,
		TableWrapComponent,
		DivWrapComponent,
		SpinnerComponent,
		PreIconComponent,
		PostIconComponent,
		LangAttrDirective,
		CancelBtnComponent,
		AcCommonComponent,
		PatientFusesComponent,
		ImportCommonComponent,
		PersonCommonComponent,
		PersonSideItemComponent,
		CcParamsSecurityComponent,
		CcParamsSelfCoordinationComponent,
		OpenInstructionsComponent,
		OpenSpecialNotificationComponent,
		GenModalComponent,
		InArrayPipe,
		TabClickDirective,
		TypesCreateComponent,
		ContactwayAddComponent,
		BtnExtComponent,
		PatientModalComponent,
		TestsMenuComponent,
		AddMeetingRequestComponent,
		CcCheckboxesComponent,
		CcInsertPatientTemplate,
		CcReports,
		PersonUpdateCommons,
		ChooseMirrorUserComponent,
		WalkthroughComponent,
		PatientDetailsFieldsManageComponent,
		PatientDetailsFieldsRowComponent,

		// PayorsTableComponent,
		// PayorpricesTableComponent
	],
	imports: [
		FullCalendarModule,
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		NgxChartsModule,
		// PdfViewerModule,
		BrowserAnimationsModule,
		// ServiceWorkerModule.register('ngsw-worker.js', {
		//   enabled: !isDevMode(),
		//   // Register the ServiceWorker as soon as the application is stable
		//   // or after 30 seconds (whichever comes first).
		//   registrationStrategy: 'registerWhenStable:30000'
		// })
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: RegInterceptor, multi: true },
		{ provide: ErrorHandler, useClass: ErrorService },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
