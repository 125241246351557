import { LangService } from "@app/services/lang.service"
import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	SimpleChange,
} from "@angular/core"
import { patientColors } from "@app/definitions/types"
import { environment } from "@env/environment"

@Component({
	selector: "app-banner",
	templateUrl: "./banner.component.html",
	styleUrl: "./banner.component.css",
})
export class BannerComponent {
	//handles the person(patient/contact/payor) banner
	@Input() isLoading: boolean = false //is in loading state
	@Input() person: any = null //the object data (of type patient/contact/payor)
	@Input() type: "patient" | "contact" | "payor" | "user" = "patient" //type of object, used for config purposes
	personBanner: any = { pic: null, bg: null } //contains the banner info itself
	@Output() onClick = new EventEmitter() //informs of a click on the banner

	constructor(private lang: LangService) {}

	ngOnChanges(changes: SimpleChange) {
		//handles change in input vars
		if (changes["person"]) {
			//if a person object has change - call loadBG
			this.loadBG()
		}
	}

	loadBG() {
		if (!this.person) {
			return
		} //person is empty - return
		let isEng = !this.lang.isRtl //used to flip the gradient
		const bg =
			(this.type == "patient"
				? patientColors[this.person.id % patientColors.length]
				: this.lang.getConfigVal("color." + this.type)) || "" //if object has color data use it, otherwise try config
		let personBg = bg.replace("#", "") //remove the # from the bg string
		this.personBanner.pic =
			environment.serverUrl + "/images/backgrounds/" + personBg + ".png" //actual pic path on server
		// var isFileExist = fileExists(patientImageUrl, patientBg);
		this.personBanner.bg = isEng
			? "linear-gradient(90deg, #" +
				personBg +
				" 0%, #" +
				personBg +
				" 20%, #" +
				personBg +
				" 30%, #" +
				personBg +
				"00 100%)"
			: "linear-gradient(90deg, #" +
				personBg +
				"00 0%, #" +
				personBg +
				" 70%, #" +
				personBg +
				" 80%, #" +
				personBg +
				" 100%)" //gradient css for background of banner
	}
}

//implement contact,payor, check if all display correctly
