import { StoreService } from '@app/services/store.service';
import { Component, Input, NgZone } from "@angular/core";
import { Configcliniq, ConfigDefinitions } from "@app/definitions/types";
import { ApiService } from "@app/services/api.service";
import { LangService } from "@app/services/lang.service";
import { ModalService } from "@app/services/modal.service";
import { ValidatorService } from "@app/services/validator.service";
import { PermsService } from '@app/services/perms.service';
import { debounceTime, Subject } from 'rxjs';


@Component({ template: '' })
export class CcParamsGen {
	configcliniq: Configcliniq;	//the config DB obj
	saveObjName: string = "";	//is used to identify which part should be saved by the api function
	errors: any = {};	//will hold errors for the fields in the form
	isFormSubmittable: boolean = false;	//can submit (controls disabled in the submit button)
	validationFields: any = {	//field validations, control displaying field errors and ability to submit the form
		// name:{'not_empty':null},

	};
  changeSubj=new Subject();

  fontSizeOpts: any[] = [	//font sizes for default text
    { id: 10, value: 10 },
    { id: 12, value: 12 },
    { id: 14, value: 14 },
    { id: 16, value: 16 },
    { id: 18, value: 18 },
    { id: 20, value: 20 },
   { id: 24, value: 24 },
   { id: 32, value: 32 },
  ];

  MaxNumberOfPaymentsOpts: any[] = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },

  ];

	constructor(protected apiService: ApiService, public lang: LangService, public validator: ValidatorService, public modalService: ModalService, public store: StoreService, public permsService: PermsService,protected zone: NgZone) {
    this.configcliniq = this.store.getCliniqDataOrExit();
    
    this.changeSubj.pipe(
      debounceTime(800),
    ).subscribe(()=>{
      this.save();
    })
  }

	validateEntireForm() {	//validate all fields and detrmine if can be submitted (or has errors)
		this.isFormSubmittable = this.validator.validateEntireForm(this.configcliniq, this.errors, this.validationFields);
	}
  validateAndSave(){
    this.validateEntireForm();
    this.changeSubj.next(true);

  }

	async save() {	//save to DB using the api

    if(!this.isFormSubmittable){return;}

		const result:any=await this.apiService.post("update_configcliniq_data",{...this.configcliniq, update:this.saveObjName});
    if (result && !result?.error) {	//success

      this.modalService.openToast(this.lang.getVal("updated_successfully"));	//open toast with success message
      sessionStorage.setItem('cliniqData', JSON.stringify(result));	//update the config object in the sessionStorage
    }
    else {	//fail
      this.modalService.openToast(this.lang.getVal(result?.error));	//open toast with fail message
    }
	}

  async warnInvoicePerFollowupChange() {
		const cliniqData = this.store.getCliniqDataOrExit();
		if (cliniqData.invoice_per_followup == "yes" && this.configcliniq.invoice_per_followup == "no") {	//the user is trying to change the value to no - make sure they understand and approve this change

			const isConfirm = await this.modalService.openMulti("confirm", {
				actionLang: this.lang.getVal("confirm_change_default_invoice_per_followup")
			});
			if (!isConfirm) {	//no confirm = user regrets - change back to "yes"
				this.configcliniq.invoice_per_followup = "yes";
			}
		}
    this.validateAndSave();

	}

}
