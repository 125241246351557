import { Injectable } from "@angular/core"
import { LangService } from "@app/services/lang.service"
import * as moment from "moment"
import { ConfigDefinitions } from "@app/definitions/types"
import { StoreService } from "./store.service"

@Injectable({
	providedIn: "root",
})
export class ValidatorService {
	constructor(
		public lang: LangService,
		private store: StoreService
	) {}

	validateEntireForm(
		actObject: any,
		errors: any,
		validationFields: any,
		objectCollection: any[] = null
	) {
		Object.keys(validationFields).forEach((fieldName) => {
			let rules = validationFields[fieldName]
			this.validateField(actObject, errors, fieldName, rules, objectCollection)
		})
		return this.formIsValid(errors)
	}
	validateEntireFormGen(
		actObject: any,
		collectionFields: any[],
		objectCollection: any[] = null
	) {
		collectionFields.forEach((fieldObj) => {
			let rules = fieldObj.validationRules
			this.validateField(
				actObject,
				actObject.errors,
				fieldObj.fieldName,
				rules,
				objectCollection
			)
		})
		return this.formIsValid(actObject.errors)
	}

	validateField(
		actObject: any,
		errors: any,
		fieldName: string,
		rulesObj: any,
		objectCollection: any[] = null
	) {
		//not_empty,str_length,not_zero
		let inputValue = actObject[fieldName] //this is the value of the field in MY row in THIS field
		errors[fieldName] = null
		/*
		'is_zero' : 'יש להקיש ערך',
	'is_empty' : 'השדה ריק',
	'one_of_them_should_be_filled' : 'שני השמות ריקים',
	'only_one_of_them_should_be_filled' : 'יש להקיש רק שם אחד',
	'no_valid_data_found' : 'לא נמצאו נתונים מתאימים',
		 */

		let re
		const today = moment.utc().startOf("day")
		let rules = Object.keys(rulesObj)
		for (let rule of rules) {
			let ruleValue = rulesObj[rule]
			switch (rule) {
				case "not_empty":
					if (inputValue?.length == 0) {
						errors[fieldName] = this.lang.getVal("is_empty")
					}
					break
				case "no_file_selected":
					if (inputValue?.length == 0) {
						errors[fieldName] = this.lang.getVal("no_file_selected")
					}
					break
				case "agree_to_terms":
					if (inputValue !== true) {
						errors[fieldName] = this.lang.getVal("agree_to_terms")
					}
					break
				case "checked":
					if (inputValue !== true) {
						errors[fieldName] = this.lang.getVal("is_not_checked")
					}
					break
				case "not_zero":
					if (inputValue == 0) {
						errors[fieldName] = this.lang.getVal("is_zero")
					}
					break
				case "not_zero_num":
					if (inputValue === 0) {
						errors[fieldName] = this.lang.getVal("is_zero")
					}
					break
				case "regex":
					if (!ruleValue.test(String(inputValue))) {
						errors[fieldName] = this.lang.getVal("wrong_text")
					}
					break
				case "regex_user_name":
					re = /(^([א-תa-zA-Z0-9- .]+)$)/u
					if (!re.test(String(inputValue))) {
						errors[fieldName] = this.lang.getVal("is_not_user_name")
					}
					break
				case "phone_format":
					re = /(^([0-9 -]+)$)/
					if (!re.test(String(inputValue))) {
						errors[fieldName] = this.lang.getVal("digits_only")
					}
					break
				case "length_user_name":
					if (String(inputValue).length < ruleValue) {
						errors[fieldName] =
							this.lang.getVal("length_user_name") +
							" " +
							ruleValue +
							" " +
							this.lang.getVal("at_least_chars")
					}
					break
				case "length_password":
					if (String(inputValue).length < ruleValue) {
						errors[fieldName] =
							this.lang.getVal("length_password") +
							" " +
							ruleValue +
							" " +
							this.lang.getVal("at_least_chars")
					}
					break
				case "regex_password":
					let res = [/[0-9]/, /[a-z]/, /[A-Z]/]
					res.forEach((re) => {
						if (!re.test(String(inputValue))) {
							errors[fieldName] = this.lang.getVal("is_not_password")
						}
					})
					break
				case "email":
					if (!this.isEmail(String(inputValue))) {
						errors[fieldName] = this.lang.getVal("invalid_email")
					}
					break
				case "bad_domains":
					if (String(inputValue).endsWith("@hotmail.com")) {
						errors[fieldName] = this.lang.getVal("no_hotmail")
					}
					break
				case "email_or_empty":
					if (inputValue?.length != 0 && !this.isEmail(String(inputValue))) {
						errors[fieldName] = this.lang.getVal("invalid_email")
					}
					break
				case "identicalToPassword":
					if (String(inputValue) != actObject.password) {
						errors[fieldName] = this.lang.getVal("not_identicalToPassword")
					}
					break
				case "is_positive": //must also be a number
					if (Number.isNaN(Number(inputValue)) || Number(inputValue) <= 0) {
						errors[fieldName] = this.lang.getVal("must_be_positive_number")
					}
					break
				case "not_null":
					if (inputValue === null) {
						errors[fieldName] = this.lang.getVal("is_empty")
					}
					break
				case "is_legit_fuzzy_value":
					if (!inputValue || !Number.isInteger(Number(inputValue))) {
						//EMPTY is bad!
						errors[fieldName] = this.lang.getVal("is_valid_value")
					}
					break
				case "is_legit_fuzzy_value_patient_contact":
					if (!inputValue || !Number.isInteger(Number(inputValue))) {
						//EMPTY is bad!
						errors[fieldName] = this.lang.getVal(
							"is_valid_value_patient_contact"
						)
					}
					break
				case "is_legit_fuzzy_value_or_empty":
					if (inputValue === null || !Number.isInteger(Number(inputValue))) {
						//EMPTY is ok!
						errors[fieldName] = this.lang.getVal("is_valid_value")
					}
					break
				case "is_legit_fuzzy_value_or_gibberish": //null/gibberish is ok!
					if (
						inputValue !== null &&
						(!inputValue || !Number.isInteger(Number(inputValue)))
					) {
						//EMPTY is bad!
						errors[fieldName] = this.lang.getVal("is_valid_value")
					}
					break
				case "is_integer":
					if (inputValue === "" || !Number.isInteger(Number(inputValue))) {
						errors[fieldName] = this.lang.getVal("is_number")
					}
					break
				case "is_decimal": //0-9.-
					if (
						inputValue === "" ||
						!Number(inputValue) ||
						String(inputValue).indexOf(",") != -1
					) {
						errors[fieldName] = this.lang.getVal("is_decimal")
					}
					break
				case "is_decimal_or_zero": //0-9.-
					if (
						inputValue === "" ||
						(inputValue != 0 && !Number(inputValue)) ||
						String(inputValue).indexOf(",") != -1
					) {
						errors[fieldName] = this.lang.getVal("is_decimal")
					}
					break
				case "has_closing_tags":
					if (
						String(inputValue).indexOf("<") != -1 ||
						String(inputValue).indexOf(">") != -1
					) {
						errors[fieldName] = this.lang.getVal("has_closing_tags")
					}
					break
				case "str_min":
					if (String(inputValue).length < ruleValue) {
						errors[fieldName] = this.lang.getVal("is_greater") + " " + ruleValue
						// + ${ruleValue} this.lang.getVal("characters");  TODO check syntax
					}
					break
				case "str_max":
					if (String(inputValue).length > ruleValue) {
						errors[fieldName] = this.lang.getVal("is_smaller") + " " + ruleValue
						// + ${ruleValue} this.lang.getVal("characters");  TODO check syntax
					}
					break
				case "str_exact_length":
					if (String(inputValue).length != ruleValue) {
						errors[fieldName] = this.lang.getVal("exactly") + " " + ruleValue
						// + ${ruleValue} this.lang.getVal("characters");  TODO check syntax
					}
					break
				case "unique":
					if (
						objectCollection &&
						objectCollection.length &&
						objectCollection.find((it) => {
							return it[fieldName] == inputValue && it.id != actObject.id
						})
					) {
						errors[fieldName] = this.lang.getVal("field_must_be_unique")
					}
					break
				case "unique_for_value":
					if (
						objectCollection &&
						objectCollection.length &&
						inputValue == ruleValue &&
						objectCollection.find((it) => {
							return it[fieldName] == inputValue && it.id != actObject.id
						})
					) {
						errors[fieldName] = this.lang.getVal("field_must_be_unique")
					}
					break
				case "unique_unless_null": //if any other value than null - must be unique
					if (
						inputValue != null &&
						objectCollection &&
						objectCollection.length &&
						objectCollection.find((it) => {
							return it[fieldName] == inputValue && it.id != actObject.id
						})
					) {
						errors[fieldName] = this.lang.getVal("field_must_be_unique")
					}
					break
				case "unique_filtered_by_other_field": //like unique but must be ALSO with identical value in a DIFFERENT field (than the one I'm trying to change) - ruleValue is the OTHER field's name
					const otherFieldToCompare = ruleValue
					if (
						(fieldName === "header_default" && inputValue === "l") ||
						(otherFieldToCompare === "header_default" &&
							actObject[otherFieldToCompare] === "l")
					) {
						continue
					}
					if (
						inputValue != null &&
						actObject[otherFieldToCompare] != null && //the value of the OTHER field in this row
						objectCollection &&
						objectCollection.length &&
						objectCollection.find((it) => {
							//looking for a DIFFERENT row (it) that will CONFLICT with my row (actObject)
							return (
								it[fieldName] == inputValue && //the other row has the SAME value in the field I'm trying to change
								it.id != actObject.id && //the other row must have a DIFFERENT id than my row
								it[otherFieldToCompare] == actObject[otherFieldToCompare]
							) //the other row MUST have the SAME value as my row in THE OTHER FIELD TO COMPARE
						})
					) {
						errors[fieldName] = this.lang.getVal("field_must_be_unique")
					}
					break
				case "file_max_size":
					if (
						actObject.filesObj &&
						actObject.filesObj[fieldName] &&
						actObject.filesObj[fieldName].size > ruleValue
					) {
						errors[fieldName] = this.lang.getVal("max_size_is_6MB")
					}
					break
				case "recent_past_and_later":
					const isRecentPast = this.store.isDateRecentPast(inputValue)
					if (!isRecentPast) {
						errors[fieldName] = this.lang.getVal("update_before_yesterday")
					}

					break
				case "larger_than":
					if (Number(inputValue) <= ruleValue) {
						errors[fieldName] = this.lang.getVal("error_in_range")
					}

					break
				case "smaller_than":
					if (Number(inputValue) >= ruleValue) {
						errors[fieldName] = this.lang.getVal("error_in_range")
					}

					break
				case "tomorrow_and_later":
					const tomorrow = moment.utc().add(1, "day").startOf("day")
					if (moment.utc(inputValue).isBefore(tomorrow)) {
						errors[fieldName] = this.lang.getVal("vacation_before_tomorrow")
					}
					break
				case "today_and_before": //also accepts empty value
					if (inputValue && moment.utc(inputValue).isAfter(today)) {
						errors[fieldName] = this.lang.getVal("after_today")
					}
					break
				case "today_and_later": //also accepts empty value
					if (inputValue && moment.utc(inputValue).isBefore(today)) {
						errors[fieldName] = this.lang.getVal("before_today")
					}
					break
				case "date_and_later": //also accepts empty value
					const date = moment.utc(ruleValue).startOf("day")
					if (
						inputValue &&
						moment.utc(inputValue).startOf("day").add(1, "h").isBefore(date)
					) {
						errors[fieldName] = this.lang.getVal("before_day")
					}
					break
				case "headture_textual":
					if (actObject.header_default == "i" && actObject.lang == "iw") {
						if (!inputValue) {
							inputValue = ""
						}
						const opts = ["ח.פ", "ע.מ", "ע.פ", "ע.ר", "עוסק מורשה", "עוסק פטור"]
						const findOpt = opts.find((opt) => inputValue.includes(opt))
						if (!findOpt) {
							actObject.errors[fieldName] = this.lang.getVal("must_include")
						}
					}
					break
				case "time_allocation":
					if (!actObject.time || !actObject.time_to) {
						actObject.errors[fieldName] = this.lang.getVal(
							"already_has_alocation"
						)
						break
					}
					const todayStr = moment
						.utc()
						.format(ConfigDefinitions.momentDateFormat)
					const sameDayTAs = objectCollection.filter(
						(it) => it.id != actObject?.id && actObject.day == it.day
					)
					if (!sameDayTAs.length) {
						break
					}

					const newStart = moment.utc(todayStr + " " + actObject.time)
					const newEnd = moment.utc(todayStr + " " + actObject.time_to)
					const findConflict = sameDayTAs.find((ta) => {
						const taStart = moment.utc(todayStr + " " + ta.time)
						const taEnd = moment.utc(todayStr + " " + ta.time_to)

						return (
							newStart.isBetween(taStart, taEnd, null, "[)") ||
							newEnd.isBetween(taStart, taEnd, null, "(]") ||
							taStart.isBetween(newStart, newEnd, null, "[)") ||
							taEnd.isBetween(newStart, newEnd, null, "(]")
						)
					})
					if (findConflict) {
						actObject.errors[fieldName] = this.lang.getVal(
							"already_has_alocation"
						)
					}

					break
			}
		}
		if (errors[fieldName]?.length) {
			const replaced = fieldName.startsWith("patientfield_id_")
				? "this_value"
				: fieldName
			errors[fieldName] = errors[fieldName].replaceAll(
				"*fieldname*",
				this.lang.getVal(replaced)
			)
		}
	}

	isEmail(s: string) {
		//no spaces!
		let regex = /^[a-z0-9_\.\-]+@[a-z0-9_\-\.]+\.+[a-z0-9_\-\.]+$/i
		// let regex = /^[a-zA-Z0-9_\.]+@[a-zA-Z0-9_\-\.]+\.+[a-zA-Z0-9_\-\.]+$/;
		return regex.test(s)
		let at = s.indexOf("@")
		let lastAt = s.lastIndexOf("@")
		let dot = s.indexOf(".", at)
		return at > 0 && at == lastAt && dot != -1
	}

	formIsValid(errors: any) {
		return Object.keys(errors).filter((key) => errors[key] != null).length == 0
	}
	hasNoErrors(errors: any) {
		return Object.keys(errors).filter((key) => errors[key] != null).length == 0
	}
}
