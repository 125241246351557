import { StoreService } from "../services/store.service"
import { Component, ViewChild } from "@angular/core"
import { FullCalendarComponent } from "@fullcalendar/angular"
import dayGridPlugin from "@fullcalendar/daygrid"
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import { CalendarOptions } from "@fullcalendar/core"
import interactionPlugin from "@fullcalendar/interaction"
import adaptivePlugin from "@fullcalendar/interaction"

const hasDelay = location.href.endsWith("delay")

@Component({
	selector: "app-fctry",
	template: `@if (doneLoading) {
		<full-calendar
			#calendar
			[options]="calendarOptions"
		/>
	}`,
})
export class FctryComponent {
	@ViewChild("calendar") calendar: FullCalendarComponent
	calendarOptions: CalendarOptions = {
		plugins: [
			dayGridPlugin,
			resourceTimeGridPlugin,
			interactionPlugin,
			adaptivePlugin,
		],
		schedulerLicenseKey: "0261304089-fcs-1701338804",
		initialView: hasDelay ? "timeGridWeek" : "timeGridDay", //timeGridDay
		initialDate: "2024-12-28",
		timeZone: "UTC",
		events: [],
		hiddenDays: [6],
		editable: false,
	}
	doneLoading: boolean = false

	constructor(protected store: StoreService) {}
	async ngOnInit() {
		if (!hasDelay) {
			this.doneLoading = true
			return
		}
		await this.store.timeout(3000)
		// this.calendar.getApi().changeView("timeGridDay")
		this.calendarOptions.initialView = "timeGridDay"
		this.doneLoading = true
	}
}
