import { Component, Input, OnInit } from '@angular/core';
import { CcParamsGen } from '@app/cc-params-gen/cc-params-gen';

@Component({
  selector: 'app-cc-params-self-coordination',
  templateUrl: './cc-params-self-coordination.component.html',
  styleUrl: './cc-params-self-coordination.component.css'
})
export class CcParamsSelfCoordinationComponent extends CcParamsGen {	//inheriting most functions and fields!
  saveObjName: string = "self-coordination";	//which part to save while updating
  apiLink:string="";
  apiGuestLink:string="";


  async ngOnInit(){
    this.getApiKeys();
    this.getGuestApiKeys();
  }
  async getApiKeys(){
    this.apiLink="";
    const meetingtypes=this.store.getSiteDataTable("meetingtypes");

    if(!this.permsService.owner_has_users){

      if(!meetingtypes.find(mt=>mt?.first_time_meeting_request=="yes") && !meetingtypes.find(mt=>mt?.return_meeting_request=="yes") ){
        this.apiLink="";
        return;
      }
    }


    if(!this.apiLink){
      this.apiLink=await this.store.getApiLink()
    }
  }




  async copyApiLink(){
    if(!this.apiLink){
      this.modalService.openMulti("confirm", {
        actionLang: this.lang.getVal("meetingtypes_and_time_allocation_shoud_be_defined"),
        hasCancel: false
      });
      return;
    }
    navigator.clipboard.writeText(this.apiLink);
    this.modalService.openToast(this.lang.getVal("copy_link"));
    window.open(this.apiLink, '_blank');
  }
  async getGuestApiKeys(){
    this.apiGuestLink=(await this.store.getApiLink()).replace('sched','payment');
  }
async copyApiGuestLink(){
    navigator.clipboard.writeText(this.apiGuestLink);
    this.modalService.openToast(this.lang.getVal("copy_link"));
    //window.open(this.apiLink, '_blank');
  }

}



