<div class="test-manage" >

  <div class="row">
    <div class="colstat colstat-big pad-btns" >
      <app-gen-button-group [actualObj]="this" fieldName="active" optionsListName="active_all_opts"  />
    </div>
  </div>
  <app-table-wrap [fieldsArr]="upperFieldsTh" [isStriped]="false"> <!--test_id,sub_test_id,test_association,short_name,-->
    <ng-container *ngTemplateOutlet="rowFields;context:{curRow:newTest,isNewRow:true}" />

    @for(test of topTests;track test){
      @if(active==="-1" || test.active==active){
        <ng-container *ngTemplateOutlet="rowFields;context:{curRow:test,is_documented:test?.is_documented,isTop:true}" />

        @if(test?.tests?.length){
          @for(subTest of test.tests;track subTest){
            @if(active==="-1" || subTest.active==active){
              <ng-container *ngTemplateOutlet="rowFields;context:{curRow:subTest,is_documented:test?.is_documented}" />
            }
          }
        }
      }
    }

  </app-table-wrap>
</div>

<ng-template #rowFields let-curRow="curRow" let-isNewRow="isNewRow" let-is_documented="is_documented" let-isTop="isTop">



  @if(isTop && !isNewRow){
    <tr>
      <td colspan="10" style="height:10px;"></td>
    </tr>
  }

  <tr [class.new-row]="isNewRow" (click)="curRow.isExpanded=true">
    @if(eventsService.isMobile){
      <td class="action-fields" >
        <button (click)="$event.stopImmediatePropagation();curRow.isExpanded = !curRow.isExpanded;" class="btn btn-icon" [icon]="(curRow.isExpanded?'narrow':'expand')" titleLang="expand"></button>
      </td>
    }

    @for(fieldName of upperFields;track fieldName){
      <td [class.actions]="fieldName=='action'" [class.name-td]="fieldName=='name'" [class.child-tr]="fieldName=='name' && !isTop && !isNewRow" >
        <ng-container *ngTemplateOutlet="fieldTypes;context:{curRow:curRow,is_documented:is_documented,isTop:isTop,isNewRow:isNewRow,fieldName:fieldName}"></ng-container>
      </td>
    }
  </tr>
  @if(eventsService.isMobile && curRow.isExpanded){
    <tr>
      <td colspan="9999999">
        <div class="expanded-div">
          @for(fieldName of mobileFields;track fieldName){
            <div>
              <label>{{fieldName | langVal}}</label>
              <ng-container *ngTemplateOutlet="fieldTypes;context:{curRow:curRow,is_documented:is_documented,isTop:isTop,isNewRow:isNewRow,fieldName:fieldName}"></ng-container>
            </div>
          }
        </div>
      </td>
    </tr>
  }
</ng-template>

<ng-template #fieldTypes let-fieldName="fieldName" let-isNewRow="isNewRow" let-is_documented="is_documented" let-isTop="isTop" let-curRow="curRow">
  @switch(fieldName){
    @case("name"){
      <app-gen-input #firstField fieldName="name"  [actualObj]="curRow" [errors]="curRow.errors" [disabled]="curRow.is_locked=='yes'"
      (keyupEvent)="validateEntireForm()" (changeEvent)="isNewRow?validateEntireForm():validateField(curRow,'name')" />
    }
    @case("patient_sign_required"){
      <app-yes-no [disabled]="curRow.is_locked=='yes'" (onClick)="yesNoClick(curRow,'patient_sign_required',isNewRow)" [isCheck]="curRow.patient_sign_required=='yes'"   ></app-yes-no>
    }
    @case("user_sign_required"){
      <app-yes-no [disabled]="curRow.is_locked=='yes'" (onClick)="yesNoClick(curRow,'user_sign_required',isNewRow)" [isCheck]="curRow.user_sign_required=='yes'"   ></app-yes-no>
    }
    @case("calculated"){
      <app-yes-no [disabled]="curRow.is_locked=='yes'" (onClick)="yesNoClick(curRow,'calculated',isNewRow)" [isCheck]="curRow.calculated=='yes'"></app-yes-no>
    }
    @case("is_locked"){
      <app-yes-no [disabled]="!isNewRow" (onClick)="yesNoClick(curRow,'is_locked',isNewRow)" [isCheck]="curRow.is_locked=='yes'"   ></app-yes-no>
    }
    @case("flip"){
      @if(isNewRow){
        <app-gen-select [actualObj]="curRow" hasNone title_ph_lang="test" fieldName="test_id"
          (changeEvent)="isNewRow?validateEntireForm():validateField(curRow,'test_id')" [options]="topTestsForSelect" />
      }
      <app-spin-or-content [isSpin]="curRow.isFlipOrderSending">
        @if(!isNewRow && curRow.test_id && curRow.is_locked=='no'){
          <div class="up-down-wrapper">
            @if(!curRow.is_lowest_order){
              <app-icon pointer icon="arrow_up" (click)="moveOrderNum(curRow,true)" title="forward" />
            }
            @if(!curRow.is_highest_order){
              <app-icon pointer icon="arrow_down" (click)="moveOrderNum(curRow,false)" title="backward" />
            }
          </div>
        }
      </app-spin-or-content>
    }
    @case("questions"){
      @if(!isNewRow && !curRow?.tests?.length){
        <button class="btn btn-icon" (click)="openQuestions(curRow,is_documented)" titleLang="questions" icon="list"></button>
      }
    }
    @case("active"){
      <app-yes-no [disabled]="curRow.is_locked=='yes'" (onClick)="yesNoClick(curRow,'active',isNewRow)" [isCheck]="curRow.active=='yes'"   ></app-yes-no>
    }

    @case("action"){
      @if(isNewRow){
        <app-btn-ext text="add" (clickEv)="saveNewTest()" [disabled]="!isFormSubmittable" [isSpin]="isNewSaving" />
      }
      @else{
        @if(!is_documented){
          <app-spin-or-content [isSpin]="curRow.isDeleting">
            <button class="btn btn-icon" (click)="deleteTest(curRow)" titleLang="delete" icon="delete"></button>
          </app-spin-or-content>
        }
        @if(isTop){
          <app-spin-or-content [isSpin]="curRow.spinDup">
            <button class="btn btn-icon" (click)="duplicateTest(curRow)"  titleLang="duplicate_test" icon="copy"></button>
          </app-spin-or-content>
        }

        <button class="btn btn-icon" (click)="goToTestUpdate(curRow)"  titleLang="update_test" [icon]="curRow.is_locked=='yes' ? 'show' : 'edit'"></button>  <!--"is_documented?'show':-->
      }
    }
  }
</ng-template>
