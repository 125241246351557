import { Component, EventEmitter, Input, Output } from "@angular/core"
import { patientDetailsFillFields } from "@app/definitions/types"
import { ApiService, pluck } from "@app/services/api.service"
import { LangService } from "@app/services/lang.service"
import { ModalService } from "@app/services/modal.service"
import { StoreService } from "@app/services/store.service"
import { Subject, debounceTime } from "rxjs"

@Component({
	selector: "app-patient-details-fields-manage",
	template: `
		<div class="row patient-details-fields">
			<div class="col-12">
				<h3>{{ "patient_details_fields" | langVal }}</h3>
				<app-table-wrap isResponsive>
					@for (field of fields; track field) {
						<tr
							patient-details-fields-row
							[field]="field"
							[show]="saveObj[field]?.show"
							[required]="saveObj[field]?.required"
							(onChange)="handleChange(field, $event)"
						></tr>
					}
					@for (field of patientfields; track field.id) {
						<tr
							patient-details-fields-row
							[field]="field.name"
							[show]="saveObj[field.fieldName]?.show"
							[required]="saveObj[field.fieldName]?.required"
							(onChange)="handleChange(field.fieldName, $event)"
						></tr>
					}
				</app-table-wrap>
			</div>
		</div>
	`,
})
export class PatientDetailsFieldsManageComponent {
	fields: string[] = patientDetailsFillFields
	patientfields: any[] = []
	saveObj: any = {}
	saveSubj = new Subject()

	constructor(
		protected store: StoreService,
		protected apiService: ApiService,
		public lang: LangService,
		public modalService: ModalService
	) {}

	ngOnInit() {
		this.saveObj =
			this.store.getCliniqDataOrExit()?.patient_details_fields_manage
		if (!this.saveObj) {
			this.saveObj = this.fields.reduce(
				(acc, item) => ({ ...acc, [item]: { show: false, required: "yes" } }),
				{}
			)
		}
		this.patientfields = this.store
			.getSiteDataTable("patientfields")
			.map((row) => ({
				id: row.id,
				name: row.name,
				fieldName: `patientfield_id_${row.id}`,
			}))
		this.patientfields.forEach(({ fieldName }) => {
			if (!(fieldName in this.saveObj)) {
				this.saveObj[fieldName] = { show: false, required: "yes" }
			}
		})

		this.saveSubj.pipe(debounceTime(800)).subscribe(() => {
			this.save()
		})
	}
	handleChange(field: string, { fieldName, value }: any) {
		this.saveObj[field][fieldName] = value
		this.saveSubj.next(1)
	}

	async save() {
		const cc = this.store.getCliniqDataOrExit()
		cc.patient_details_fields_manage = this.saveObj

		const result: any = await this.apiService.post("update_configcliniq_data", {
			...cc,
			update: "user",
		})
		if (result && !result?.error) {
			//success
			this.modalService.openToast(this.lang.getVal("updated_successfully")) //open toast with success message
			sessionStorage.setItem("cliniqData", JSON.stringify(result)) //update the config object in the sessionStorage
		} else {
			//fail
			this.modalService.openToast(this.lang.getVal(result?.error)) //open toast with fail message
		}
	}
}

@Component({
	selector: "[patient-details-fields-row]",
	template: `
		<td>
			<input
				[checked]="show"
				(change)="handleUpdate('show', $event.target.checked)"
				type="checkbox"
			/>
		</td>
		<td>
			{{ field | langVal }}
		</td>
		<td>
			<app-gen-button-group
				[actualObj]="this"
				fieldName="required"
				optionsListName="requiredOpts"
				title_ph_lang="required"
				(changeEvent)="handleUpdate('required', required)"
			/>
		</td>
	`,
})
export class PatientDetailsFieldsRowComponent {
	@Input() field: string
	@Input() show: boolean
	@Input() required: string
	@Output() onChange = new EventEmitter<any>()

	handleUpdate(fieldName: string, value: any) {
		this.onChange.emit({
			fieldName,
			value,
		})
	}
}
