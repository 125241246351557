import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	SimpleChange,
	ViewChild,
} from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Modalable } from "@app/Modalable"
import {
	HomePersonColumns,
	patientColors,
	ReportItemType,
	yes_no_opts,
} from "@app/definitions/types"
import { GenTableComponent } from "@app/gen-table/gen-table.component"
import { ApiService, AvailablePostPaths } from "@app/services/api.service"

@Component({
	selector: "app-person-common",
	templateUrl: "./person-common.component.html",
	styleUrl: "./person-common.component.css",
})
export class PersonCommonComponent extends Modalable {
	personType: ReportItemType = "" //used to generalize data
	@Input() id: number = 0
	@Input() showColumn: HomePersonColumns = "all" //which column is currently shows (relevant for mobile display)
	@Output() updateShowColumn = new EventEmitter() //inform parent of a change in display column
	curCenter: string = "" //current center content area name
	mobileChangeSub: any = null //listens to changes in isMobile from the eventsService
	@ViewChild("center") center: ElementRef //the center container (used for scrolling)
	@ViewChild("finresourceTable") finresourceTable: GenTableComponent

	@Input() contact: any = null //the object that holds the field values (and is saved)
	@Input() payor: any = null //the object that holds the field values (and is saved)
	@Input() patient: any = null //the object that holds the field values (and is saved)
	is_finresource_connected_only: yes_no_opts = "no"

	sideItems: any[] = [
		//the items on the side column (icon comes from config, should flag (iconIsImg) if it's img. instead of icon. )
		{
			name: "contactway",
			title: "contactway",
			icon: "contactway",
			iconIsImg: true,
		},
		// { name: 'reminder', title: 'reminders', icon: 'reminder', iconIsImg: true },
		{ name: "invoice", title: "financial", icon: "money", iconIsImg: true },
	]
	color: string = ""

	finresouecrActionSub: any = null

	async onIdChange(id: number) {
		let funcName: AvailablePostPaths = "get_patient_data" //name of the api function to use
		switch (this.personType) {
			case "contact":
				funcName = "get_contact_data"
				break
			case "payor":
				funcName = "get_payor_data"
				break
		}

		const res: any = await this.apiService.post(funcName, { id })
		this[this.personType] = res //load it to the local variable (ex: this.contact)
		this.apiService.sendApiGetPerms(this.load.bind(this))
		// this.load();	//proceed to load
	}
	isMobileHandle() {
		//sets default for mobile view and subscribed to changes in isMobile
		if (this.eventsService.isMobile) {
			//if mobile - default to column 2 (center)

			this.showColumn = "center"
		}
		this.mobileChangeSub = this.eventsService.isMobileSubj.subscribe(() => {
			//subscribe to changes in isMobile
			this.showColumn = this.eventsService.isMobile ? "center" : "all" //default is 2 (center)
		})
	}
	ngOnDestroy() {
		//make sure to unsubscribe from the isMobile changes
		this.mobileChangeSub.unsubscribe()
		this.finresouecrActionSub.unsubscribe()
	}
	ngOnInit() {
		this.isMobileHandle() //PersonCommons method to handle changes in isMobile
		this.load()
	}

	load() {
		//process data after load
		this.curCenter = "" //reset current center content area
		if (!this[this.personType]) {
			return
		} //nothing in the person object - exit
		this.is_finresource_connected_only = "no"

		this.color = this.lang.getConfigVal("color." + this.personType)
		if (this.personType == "patient") {
			this.color = patientColors[this.patient.id % patientColors.length]
		}

		if (this[this.personType].contactways) {
			this[this.personType].contactways.forEach((contactway) => {
				//iterate contactways
				contactway.contactway =
					contactway.contactway_dec ?? contactway.contactway //make sure descrypted value is being transfered
				contactway.name = contactway.name_dec ?? contactway.name //make sure descrypted value is being transfered
				contactway.link =
					(contactway.contactway_identifier == "phone" ? "tel:" : "mailto:") +
					contactway.contactway //create a link attribute (according to phone/mail differences)
			})
		}

		this.finresouecrActionSub = this.store.finresouecrActionSubj.subscribe(
			() => {
				if (this.finresourceTable) {
					this.finresourceTable.getItems()
				}
				this.store.postFinresourceRefresh(
					this.personType,
					this[this.personType]
				)
			}
		)

		this.perpOtherSideCols()
	}
	perpOtherSideCols() {}

	ngOnChanges(changes: SimpleChange) {
		//if person changed (ex: contact) - send to load function
		if (changes[this.personType]) {
			this.load()
		}
		if (changes["id"]) {
			this.onIdChange(Number(this.id))
		}
	}
	backToMain() {
		//reset current center content area
		this.curCenter = ""
		this.updateShowColumnLocal("sideItems")
	}

	async addInvoice() {
		let description = ""
		if (this.personType == "patient") {
			//if personType is patient, create a more specific description
			description =
				this[this.personType].name +
				this.store.getPatientAndMaybeIdNumber(this[this.personType].id, true) +
				"-" +
				this.lang.getVal("treatment")
		}
		await this.modalService.openMulti("add-invoice", {
			//open the modal with fields relevant to this personType
			description,
			paid_by: this[this.personType].name,
			is_withholding_tax: this[this.personType].is_withholding_tax,
			withholding_tax_percent: this[this.personType].withholding_tax_percent,
			for: this[this.personType].name,
			resource: this.personType,
			resourceId: this[this.personType].id,
		})
		this.scrollTop()
	}
	openBankaccounts() {
		//open the bank accounts modal (gen-table) filtered by this person's id
		this.modalService.openMulti("gen-table-display", {
			tableName: "bankaccounts",
			filterField: `${this.personType}_id`,
			filterFieldValue: this[this.personType].id,
		})
	}

	openReport(reportName: string) {
		//open the reports modal with this person filtered
		this.modalService.openMulti("reports", {
			report_name: reportName,
			filterFieldName: "item_id",
			filterFieldValue: this[this.personType].id,
			filterFieldShown: this[this.personType].name,
			item_type: this.personType,
			hidePersonFilters: true,
		})
	}

	updateShowColumnLocal(col: HomePersonColumns) {
		//update the currently shown column, and emit to parent that the value changed
		this.showColumn = col
		this.updateShowColumn.emit(col)
		this.scrollTop()
	}
	scrollTop() {
		this.eventsService.scrollToTop()
		if (this.center && this.center.nativeElement) {
			this.center.nativeElement.scrollTop = 0
		}
	}

	leftSideUpdate(rows: any[], tableToUpate: string) {
		//on receiving changes in collections (ex: contactways) from gen-tables etc., make sure to update the person (ex: contact) itself with those changes
		this[this.personType][tableToUpate] = [...rows]
	}
}

@Component({
	selector: "app-person-side-item",
	template: `
		<div
			class="person-side-item"
			[style.box-shadow]="color + ' 0 5px 15px -10px'"
			[class.active]="curCenter == sideItem.name"
		>
			<!-- [style.background-color]="color" -->
			<div [titleLang]="sideItem.title">
				<app-icon
					[icon]="sideItem.icon"
					[isImg]="sideItem.iconIsImg"
					[titleLang]="sideItem.title"
				/>
				<div>{{ sideItem.title | langVal }}</div>
			</div>
			<div>
				<ng-content></ng-content>
			</div>
		</div>
	`,
})
export class PersonSideItemComponent {
	@Input() color: string
	@Input() sideItem: any
	@Input() curCenter: string
}
