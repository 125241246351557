import { Component, OnInit ,Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, pluck } from '@app/services/api.service';
import { EventsService } from '@app/services/events.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-guest-sched',
  templateUrl: './guest-sched.component.html',
  styleUrl: './guest-sched.component.css'
})
export class GuestSchedComponent implements OnInit {
  environment = environment;
  meeting_request_with_calendar:boolean=false;
  dataObj:any=null;
  fdsewrfsdfds:string="";
  hgfe5ty6hjtgft454tr:string="";
  saveObj:any={};
  hideNoCalendarTitle:boolean=false;

  constructor(public eventsService:EventsService,private apiService: ApiService,protected route: ActivatedRoute) { }
  ngOnInit(){
    setTimeout(()=>{      
      location.reload();
    }, 20 * 60 * 1000 );

    this.route.queryParams.subscribe(params=>{
      Object.keys(params).forEach(key=>{
        this[key]=params[key];
      });
      if(this.fdsewrfsdfds && this.hgfe5ty6hjtgft454tr){
        this.load();
      }
    });
  }
  async load(){
    this.dataObj=await this.apiService.post("guest_get_all_fus",this,"fdsewrfsdfds,hgfe5ty6hjtgft454tr");
    if(this.dataObj?.website){
      const website=this.dataObj.website;
      if(website.indexOf("http://")==-1 && website.indexOf("https://")==-1){
        this.dataObj.website="http://"+website;
      }
    }
    this.meeting_request_with_calendar= this.dataObj?.meeting_request_with_calendar;

    this.saveObj={fdsewrfsdfds:this.fdsewrfsdfds,hgfe5ty6hjtgft454tr:this.hgfe5ty6hjtgft454tr};
  }
  backFromHours(obj:any){
    this.saveObj={...this.saveObj,...obj};
    this.meeting_request_with_calendar=false;
  }

  back(){
    //history.back();
    this.meeting_request_with_calendar=false;
  }
}
