import { GenTableComponent } from "@app/gen-table/gen-table.component"
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	SimpleChange,
	ViewChild,
	booleanAttribute,
} from "@angular/core"
import {
	ConfigDefinitions,
	Followup,
	PatientFormObj,
	Test,
	PatientTestObj,
	TimeStatuses,
	ReportItemType,
	StorePatient,
} from "@app/definitions/types"
import * as moment from "moment"
import { MixedListComponent } from "@app/mixed-list/mixed-list.component"
import { PersonCommonComponent } from "@app/person-common/person-common.component"

@Component({
	selector: "app-patient",
	templateUrl: "./patient.component.html",
	styleUrls: [
		"./patient.component.css",
		"../person-common/person-common.component.css",
	],
})
export class PatientComponent extends PersonCommonComponent {
	//inherits some fields and methods from PersonCommons!
	modalTitleLang: string = "edit_patient"
	modalWidth: string = "modal-full-width"
	hasExit: boolean = true
	spinnerFieldName: string = ""

	lastMonth: string = moment
		.utc()
		.subtract(1, "month")
		.format("MMM")
		.toLowerCase()
	personType: ReportItemType = "patient" //used by PersonCommons to generalize
	isHomeMeetingOnly: boolean = false
	@Input() patient: any = null //the object that holds the field values (and is saved)
	@Input() followup: Followup = null
	@Input() isLoading: boolean = false
	@Input({ transform: booleanAttribute }) isHome: boolean = false
	@Input() timeStatus: TimeStatuses = "present"
	@Input() dayEventsLength: number = 0
	@Output() moveToOtherFollowup = new EventEmitter()
	@ViewChild("documentsTable") documentsTable: GenTableComponent
	@ViewChild("prescriptionsTable") prescriptionsTable: GenTableComponent
	@ViewChild("mixedList") mixedList: MixedListComponent

	escSub: any = null
	prevNextSub: any = null
	miscSub: any = null
	patientCollectionsSub: any = null
	isDownloading: boolean = false
	selectedDocumentsObj: any[] = []
	tests: Test[] = []
	testsChooseAllChecked: boolean = false
	hasCheckedItems: boolean = false
	isSendingMulti: boolean = false

	sideItems: any[] = []
	isSendingProformaEmail: boolean = false
	isSendingProformaPhone: boolean = false
	isSendingProformaWhatsapp: boolean = false

	changeCurCenterToPatientDetails() {
		this.curCenter = this.isHome ? "patient_details" : ""
	}

	ngOnDestroy() {
		this.escSub.unsubscribe()
		this.prevNextSub.unsubscribe()
		this.miscSub.unsubscribe()
		this.patientCollectionsSub.unsubscribe()
		this.mobileChangeSub.unsubscribe()
	}

	src: any = null

	ngOnInit() {
		this.changeSideItems()

		this.curCenter = this.isHome ? "" : ""

		this.escSub = this.eventsService.escapeKeyNonModalSubj.subscribe(() => {
			this.backToMain()
		})

		this.prevNextSub = this.store.patientPrevNextSub.subscribe((res: any) => {
			if (this.patient && res.patientId == this.patient.id) {
				this.patient.last_followup = res.last_followup
				this.patient.next_followup = res.next_followup
			}
		})
		this.miscSub = this.store.patientMiscSub.subscribe((res: any) => {
			if (this.patient && res.patient_id == this.patient.id) {
				this.store.totalFields.forEach(
					(f) => (this.patient[f] = res.patient[f])
				)
			}
		})
		this.patientCollectionsSub = this.store.patientCollectionsSub.subscribe(
			(res: any) => {
				if (this.patient && res.patient_id == this.patient.id) {
					this.patient[res.name] = res.collection
					switch (res.name) {
						case "mixed_list":
							if (this.mixedList) {
								this.mixedList.ngOnInit()
							}
							break
						case "documents":
							this.leftSideUpdate(res.collection, "documents")

							if (this.curCenter == "document") {
								this.documentsTable.getItems()
							}
							break
						case "finResource_list":
							if (this.curCenter == "invoice") {
								this.finresourceTable.getItems()
							}
							break
						case "prescriptions":
							if (this.curCenter == "medicpatient") {
								this.prescriptionsTable.getItems()
							}
							break
						case "tests":
							this.leftSideUpdate(res.collection, "tests")
							break
					}
				}
			}
		)

		//create in store and listen here to invoice table updates

		this.isMobileHandle() //PersonCommons method to handle changes in isMobile
		this.tests = []
		this.testsChooseAllChecked = false
		this.hasCheckedItems = false
		this.isSendingMulti = false
		this.refreshTestsList()
	}
	changeSideItems() {
		this.sideItems = [
			//the items on the side column (icon comes from config, should flag (iconIsImg) if it's img. instead of icon. )
			{ name: "tasks", title: "tasks", icon: "remarks" },
			{
				name: "contactway",
				title: "contactway",
				icon: "contactway",
				iconIsImg: true,
			},
			{ name: "diagppatient", title: "diagps", icon: "diagp", iconIsImg: true },
			{
				name: "mixed_list",
				title: "mixed_list",
				icon: "calendar1",
				iconIsImg: true,
			},
			/* {name: 'reminder', title: 'reminders', icon: 'reminder', iconIsImg: true },*/
			{ name: "invoice", title: "financial", icon: "money", iconIsImg: true },
			{
				name: "document",
				title: "documents",
				icon: "document",
				iconIsImg: true,
			},
			{ name: "test", title: "tests", icon: "test", iconIsImg: true },
			{ name: "medicpatient", title: "medics", icon: "medic", iconIsImg: true },
			{
				name: "nondrugpatient",
				title: "nondrugs",
				icon: "nondrug",
				iconIsImg: true,
			},
			{
				name: "labtestpatient",
				title: "labtests",
				icon: "labtest",
				iconIsImg: true,
			},
			{
				name: "details",
				title: "group_details",
				icon: "group",
				iconIsImg: false,
			},
		]

		if (
			this.patient?.is_group == "yes" &&
			this.patient?.charge_mode == "by_participant"
		) {
			const areasToRemove = [
				"contactway",
				"diagppatient",
				"medicpatient",
				"nondrugpatient",
				"labtestpatient",
				"test",
			]
			this.sideItems = this.sideItems.filter(
				(it) => !areasToRemove.includes(it.name)
			)
		}
		if (
			this.patient?.is_group == "yes" &&
			this.patient?.charge_mode == "by_group"
		) {
			const areasToRemove = [
				"contactway",
				"diagppatient",
				"medicpatient",
				"nondrugpatient",
				"labtestpatient",
				"test",
			]
			this.sideItems = this.sideItems.filter(
				(it) => !areasToRemove.includes(it.name)
			)
		}

		if (
			this.permsService.owner_has_users &&
			!this.permsService.perms?.clinical &&
			this.sideItems.find((it) => it.name == "medicpatient")
		) {
			const areasToRemove = [
				"medicpatient",
				"nondrugpatient",
				"diagppatient",
				"labtestpatient",
				"mixed_list",

			]
			this.sideItems = this.sideItems.filter(
				(it) => !areasToRemove.includes(it.name)
			)
		}
		if (
			!this.permsService?.perms?.clinic_financial &&
			!this.permsService?.perms?.administrative &&
			this.sideItems.find((it) => it.name == "invoice")
		) {
			const areasToRemove = ["invoice"]
			this.sideItems = this.sideItems.filter(
				(it) => !areasToRemove.includes(it.name)
			)
		}

		const zonesCc = this.store.getCliniqDataOrExit()?.zones
		if (!zonesCc?.length) {
			this.sideItems = this.sideItems.filter((it) => it.name != "tasks")
		}
		if (zonesCc && zonesCc?.length) {
			const newSiteItems = []
			zonesCc.forEach((zone_id) => {
				const zoneName = this.store.searchSiteDataItem(zone_id, ["zones"])?.name
				const sideItem = this.sideItems.find((si) => si.name == zoneName)
				if (sideItem) {
					newSiteItems.push(sideItem)
				}
			})
			if (this.patient?.is_group == "yes") {
				const sideItem = this.sideItems.find((si) => si.name == "details")
				if (sideItem) {
					newSiteItems.push(sideItem)
				}
			}

			this.sideItems = [...newSiteItems]
		}
		this.load()
	}
	refreshTestsList() {
		const ccPublicTests = this.store.getCliniqDataOrExit()?.public_tests
		const ids = ccPublicTests || []
		this.tests = this.store
			.getSiteDataTable("tests")
			.filter(
				(t) =>
					!t.test_id &&
					t.active === "yes" &&
					(t.public === "no" || ids.includes(t.id))
			)
	}

	async ngOnChanges(changes: SimpleChange) {
		//if person changed (ex: contact) - send to load function
		if (changes[this.personType]) {
			this.postChange()
		}
		if (changes["id"]) {
			this.patient = await this.store.getPatient(+this.id)
			this.postChange()
		}
	}
	postChange() {
		this.load()
		this.changeSideItems()
		this.prepTestAnswers()
	}

	perpOtherSideCols() {
		const colsToUpdate = ["medicpatients", "diagppatients", "nondrugpatients"]
		colsToUpdate.forEach((colName) => {
			this.leftSideUpdate(this.patient[colName], colName)
		})
	}

	leftSideUpdate(rows: any[], patientTableToUpate: string) {
		//
		// if (["medicpatients","diagppatients","nondrugpatients"].includes(patientTableToUpate)) {
		//   rows = rows.filter(item => item.end_date == null);
		// }
		this.patient[patientTableToUpate] = [...rows]
	}

	openUploadDocumentModal() {
		this.modalService.openMulti("upload-document", {
			patient_id: this.patient.id,
			followup_id: this.followup?.id || 0,
			title: this.lang.getVal("document"),
		})
	}

	addDocument() {
		this.modalService.openMulti("add-document", {
			patient_id: this.patient.id,
			title:
				this.patient.name +
				" " +
				(this.patient.id_number
					? this.lang.getVal("id_number") + " " + this.patient.id_number
					: ""),
			recipient: this.patient.name,
			resource: "patient",
			resourceId: this.patient.id,
		})
	}
	addMedicalLetter() {
		const patCopy: any = this.patient
		const today = moment.utc().format(ConfigDefinitions.momentDateFormat)
		let diagppatientText = this.lang.getVal("diagppatients")
		let labtestpatientText = this.lang.getVal("labtests")
		let treatmentText = this.lang.getVal("continue_treatment")
		//current followup description + diagppatients table + labtestpatient  today +medicpatients+ medicpatients
		let preText = this.followup?.description
			? `<div>${this.followup.description}</div>`
			: ""
		if (patCopy?.diagppatients.length) {
			preText += `<div>${diagppatientText}:</div>`
			patCopy?.diagppatients.forEach((dpp) => {
				preText += `<div>${dpp.diagp_name}</div>`
			})
		}
		if (patCopy?.labtestpatients.length) {
			const postFilter = patCopy.labtestpatients.filter(
				(ltp) => ltp.date == today
			)
			if (postFilter.length) {
				preText += `<div>${labtestpatientText}:</div>`
				postFilter.forEach((ltp) => {
					preText += `<div>${ltp.labtest_name}</div>`
				})
			}
		}
		if (patCopy?.medicpatients.length) {
			preText += `<div>${treatmentText}:</div>`
			patCopy?.medicpatients.forEach((mp) => {
				preText += `<div>${mp.medic_name} ${mp.amount} X ${mp.frequency} / ${mp.frequency_unit}</div>`
			})
		}
		this.modalService.openMulti("add-document", {
			patient_id: this.patient.id,
			title:
				this.patient.name +
				" " +
				(this.patient.id_number
					? this.lang.getVal("id_number") + " " + this.patient.id_number
					: ""),
			recipient: this.patient.name,
			resource: "patient",
			resourceId: this.patient.id,
			followup_id: this.followup?.id || 0,
			followup: this.followup || null,
			preText,
			isMedicalLetter: true,
		})
	}

	updateFinResourceIds(arrItems: any[]) {
		this.selectedDocumentsObj = arrItems
	}

	async sideItemClick(name: string) {
		if (name === "details") {
			return this.changeCurCenterToPatientDetails()
		}
		if (name == "tasks") {
			return this.modalService.openMulti("task", { patientRow: this.patient })
		}
		if (name == "document") {
			const suc = await this.store.requireDefaultSignature()
			if (!suc) {
				return
			}
		}

		this.curCenter = name
		this.updateShowColumnLocal("center")
	}
	async addPrescription() {
		let description = ""
		let initPermanentRemarks = ""

		await this.modalService.openMulti("add-prescription", {
			//open the modal with fields relevant to this personType
			patient_id: this.patient.id,
			initPermanentRemarks,
		})
		this.scrollTop()
	}
	async sendProforma(isBy: string = null) {
		//get the prev and next followup to be displayed in home page side menu
		this.spinnerFieldName = "isSendingProformaWhatsapp"
		if (isBy == "email") {
			this.spinnerFieldName = "isSendingProformaEmail"
		} else if (isBy == "sms") {
			this.spinnerFieldName = "isSendingProformaPhone"
		}
		this[this.spinnerFieldName] = true
		await this.store.sendProforma(this.patient, isBy)
		this[this.spinnerFieldName] = false

		// if(!isEmail){
		//   const contactways=this.patient.contactways;
		//   let phone=contactways.find(c=>c.contactway_identifier=="phone" && c.main=="yes");
		//   if(!phone){
		//     phone=contactways.find(c=>c.contactway_identifier=="phone");
		//     if(!phone?.contactway){
		//       this.modalService.openToast(this.lang.getVal("no_valid_phone"));
		//       return;
		//     }
		//   }
		// }

		// const spinnerFieldName=isEmail ? "isSendingProformaEmail" : "isSendingProformaPhone";
		// this[spinnerFieldName]=true;
		// const res=await this.apiService.post("pre_send_proforma_text",{patient_id:this.patient.id});
		// this[spinnerFieldName]=false;

		// const message_text=res.message_text;

		// if(!message_text){
		//   this.modalService.openToast(this.lang.getVal("no_followups_or_debt"));
		//   return;
		// }

		// const confirmSend = await this.modalService.openMulti("confirm",{
		//   actionLang: this.lang.getVal("confirm_sending"),
		//   freeText: message_text.replace(/\r/g,"<br />")
		//   });
		// if(!confirmSend){return;}

		// let emailsList=null;
		// if(isEmail){
		//   emailsList = await this.modalService.openMulti("gen-table-display", {
		//     tableName: 'contactways',
		//     filterField: "patient_id",
		//     filterFieldValue: this.patient.id,
		//     fieldNameInItemToDisplay: "contactway",
		//     isCheckItems: true,
		//     filterFieldApiObject:{contactway_identifier:"email"},
		//   });
		//   if(!emailsList){return;}
		// }

		// this[spinnerFieldName]=true;
		// await this.apiService.post("send_proforma_text",{patient_id:this.patient.id,emailsList});
		// this[spinnerFieldName]=false;
		// this.modalService.openToast(this.lang.getVal("sent_successfully"));
	}

	prepTestAnswers() {
		if (!this.patient) {
			return
		}
		this.store.getPatientWithPreppedTests(this.patient.id)
	}

	getTestHtml(test: PatientTestObj) {
		let html = ""
		if (test?.tests?.length) {
			for (let testInner of test.tests) {
				html += `
        <div>
          <h3>${testInner.name}</h3>
          ${this.getTestHtml(testInner)}
        </div>`
			}
		}
		if (test?.questions?.length) {
			for (let ques of test.questions) {
				html += `
        <div>
          <b style="display: block;">${ques.q}</b>
          ${ques.a}
        </div>`
			}
		}
		return html
	}

	async download_testfill(testRow: any) {
		testRow.isDownloading = true
		this.store.downloadToastFunc(async () => {
			const res = await this.apiService.download("download_testfill", {
				id: testRow.testfill_id,
			})
			testRow.isDownloading = false
			if (!res?.size) {
				return this.store.downloadFailedModal(50)
			}
			this.apiService.downloadFile(res, this.lang.getVal("test") + ".pdf")
		})
	}

	async openTestManage() {
		//await this.modalService.openMulti("test-manage");
		const res = await this.modalService.openMulti("tests-menu", {
			patient_id: this.patient.id,
		})
		this.refreshTestsList()
		if (res) {
			this.refreshTestfills()
		}
	}

	async refreshTestfills() {
		const res = await this.apiService.post("get_patient_test_fills_answers", {
			patient_id: this.patient.id,
		})
		if (res?.testfills) {
			this.store.removeDecArr(res.testanswers)
			this.store.removeDecArr(res.testfills)
			this.store.updatePatientGenCollection(
				this.patient.id,
				res.testfills,
				"testfills"
			)
			this.store.updatePatientGenCollection(
				this.patient.id,
				res.testanswers,
				"testanswers"
			)

			this.prepTestAnswers()
		}
	}

	testsChooseAll() {
		this.patient.tests.forEach((t) => (t.checked = this.testsChooseAllChecked))
		this.postTestChoose()
	}
	postTestChoose() {
		this.hasCheckedItems = !!this.patient.tests.find((t) => t.checked)
	}
	async printTests() {
		const selectedItems = this.patient.tests.filter((row) => row.checked)
		if (!selectedItems.length) {
			return
		}
		for (let obj of selectedItems) {
			await this.store.downloadToastFunc(async () => {
				const res = await this.apiService.download("download_testfill", {
					id: obj.testfill_id,
				})
				if (!res?.size) {
					return this.store.downloadFailedModal(50)
				}
				this.apiService.downloadFile(res, "test-" + obj.testfill_id + ".pdf")
			})
		}
	}
	async sendTests() {
		const selectedItems = this.patient.tests.filter((row) => row.checked)
		if (!selectedItems.length) {
			return
		}
		const testfill_ids = selectedItems.map((it) => it.testfill_id)
		const contactways: any = await this.modalService.openMulti(
			"gen-table-display",
			{
				tableName: "contactways",
				filterField: "patient_id",
				filterFieldValue: this.patient.id,
				fieldNameInItemToDisplay: "contactway",
				filterFieldApiObject: { contactway_identifier: "email" },
				isCheckItems: true,
			}
		)
		if (!contactways?.length) {
			return
		}
		const contactway_ids = contactways.map((row) => row.id)
		this.isSendingMulti = true
		const res: any = await this.apiService.post("email_tests", {
			testfill_ids,
			contactway_ids,
		})
		this.modalService.openToast(this.lang.getVal("sent_to"))
		this.isSendingMulti = false
	}
}

// for side scrolling:

// /////////////////
// isInTouchDrag:boolean=false;
// sideWidth:number=991;
// scrollEndTo:any=null;
// manageWidth(){
//   this.sideWidth=document.documentElement.clientWidth;
// }
// @HostListener('window:resize')
// onResize(event) {
//   this.manageWidth();
// }
// @HostListener('window:touchstart')
// onTouchstart() {
//   this.isInTouchDrag=true;
// }
// @HostListener('window:touchend')
// onTouchend(){
//   this.isInTouchDrag=false;
//   this.manageScrollEndTo();
// }
// @HostListener('window:scroll',['$event'])
// onScroll(event:Event) {
//   if(this.isInTouchDrag){return;}
//   this.manageScrollEndTo();
// }
// clearScrollEndTo(){
//   clearTimeout(this.scrollEndTo);
// }
// manageScrollEndTo(){
//   this.clearScrollEndTo();
//   this.scrollEndTo=setTimeout(this.onScrollEnd.bind(this),100);
// }
// onScrollEnd() {
//   this.scrollEndTo=null;
//   const scroll=-document.documentElement.scrollLeft;
//   const isInSideItems= scroll > this.sideWidth/2;
//   this.updateShowColumnLocal( isInSideItems ? "sideItems" : "center");
// }
// async scrollTop(isDiff:boolean=false) {
//   await this.store.timeout(10);
//   const y= - ( this.showColumn - 2 ) * this.sideWidth;

//   // window.scrollTo({top:0,left:y});
//   let obj:any={left:y};
//   if(isDiff){
//     obj.top=0;
//   }
//   document.documentElement.scrollTo(obj);
//   // document.documentElement.scrollTo(0,y);

// }
// scrollToTop(){
//   // document.documentElement.scrollTo({top:0});
//   if (this.center && this.center.nativeElement) {
//     this.center.nativeElement.scrollTop = 0;
//   }
// }

// updateShowColumnLocal(num: number) {	//update the currently shown column, and emit to parent that the value changed
//   const isDiff=num!=this.showColumn;
//   this.clearScrollEndTo();
//   this.showColumn = num;
//   this.updateShowColumn.emit(num);
//   this.scrollTop(isDiff);
// }
/////////////
